import { useLingui } from '@lingui/react';
import classnames from 'classnames';
import React, { FC, memo } from 'react';

import { Image } from 'common/Image/Image';
import { ArrowRightIcon } from 'common/icons/ArrowRightIcon';
import { Heading4 } from 'uikit/Heading';
import { TextP2 } from 'uikit/Text';

import './ArticleCard.scss';
import { ArticlePreviewItem } from './ArticleList';

type Props = {
  className?: string;
  titleTagName?: string;
  data: ArticlePreviewItem;
};

const ArticleCard: FC<Props> = (props) => {
  const postDate: Date = new Date(props.data.createdAt);
  const { i18n } = useLingui();
  const formattedDate: string = i18n.date(postDate, {
    year: 'numeric',
    month: 'long',
    day: '2-digit',
  });

  const hostCN = classnames('articleCard', props.className);

  return (
    <div className={hostCN}>
      <Image
        className="articleCard__image"
        src={props.data.img}
        lazy
        webp
        multiPixelRatios
        defaultJPEG
        size="xl"
        alt=""
        width="300"
        height="204"
      />
      <div className="articleCard__body">
        {/* @ts-expect-error type mismatch */}
        <Heading4 as={props.titleTagName || 'h3'} className="articleCard__title">
          <a href={props.data.link} className="articleCard__clickable" target="_blank" rel="noreferrer">
            {props.data.title}
          </a>
        </Heading4>
        {props.data.description && <TextP2 className="articleCard__description">{props.data.description}</TextP2>}
        <div className="articleCard__bottom">
          <TextP2 as="time" dateTime={props.data.createdAt} className="articleCard__date">
            {formattedDate}
          </TextP2>
          <ArrowRightIcon className="articleCard__icon" aria-hidden />
        </div>
      </div>
    </div>
  );
};

export default memo(ArticleCard);
