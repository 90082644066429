import { t } from '@lingui/macro';
import c from 'classnames';
import { pathToRegexp } from 'path-to-regexp';
import React, { MouseEvent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import analytics from 'utils/analytics/analytics';
import localStorage from 'utils/localStorage/localStorage';
import routes from 'utils/routeTranslator';

import ServiceList from '../ServiceList/ServiceList.async';
import ServiceSuggest from '../ServiceSuggest/ServiceSuggest';
import {
  normalizeString,
  Service,
  ServiceSearch$Props,
  useSearchL4CategoriesLazyQuery,
  usePopularCategoriesLazyQuery,
  formatSuggestions,
} from './ServiceSearch.helpers';
import './ServiceSearch.scss';

const MIN_CHARS_TO_SEARCH = 3;

const ServiceSearch: React.FC<ServiceSearch$Props> = ({ inHeader, onFocus, onBlur, onServiceSelect }) => {
  const [isServiceListOpen, setIsServiceListOpen] = useState(false);
  const [suggestValue, setSuggestValue] = useState('');

  const { city } = useParams<{ city?: string }>();

  const [getSearchCategories, { data: searchCategoriesData }] = useSearchL4CategoriesLazyQuery();
  const searchL4Categories = searchCategoriesData?.searchL4Categories;
  const [getPopularCategories, { data: popularCategoriesData }] = usePopularCategoriesLazyQuery();
  const userPopularServices = popularCategoriesData?.userPopularServices;

  const suggestions =
    searchL4Categories && searchL4Categories.length > 0
      ? searchL4Categories
      : formatSuggestions(userPopularServices) || [];

  const suggestionsIds: string[] = suggestions.map((s: Service) => s.id);
  const suggestionsNames: string[] = suggestions.map((s: Service) => s.name);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!suggestValue) return;
      analytics.user.searchQuery(suggestValue, suggestionsIds, suggestionsNames);
    }, 1000);
    return () => {
      clearTimeout(timeout);
    };
  }, [suggestValue, suggestionsIds, suggestionsNames]);

  const handleFocus = () => {
    analytics.user.searchStart('header');

    getPopularCategories();
    if (typeof onFocus === 'function') {
      onFocus();
    }
  };

  const handleServiceSelect = (service: Service, isModal = false) => {
    const touchPointButton = isModal ? 'popin' : 'list_main';

    setIsServiceListOpen(false);
    setSuggestValue(service.name);

    if (typeof onServiceSelect === 'function') {
      onServiceSelect();
    }

    setWizardTouchPointButton();

    analytics.user.searchComplete(suggestValue, service.id, suggestionsIds, suggestionsNames, touchPointButton);
    window.location.href = routes.get('front::category', { slug: service.slug, city });
  };

  const onServiceListClose = () => setIsServiceListOpen(() => false);

  const setWizardTouchPointButton = () => {
    const url = window.location.pathname;
    const categoriesRoute = routes.get('categories_seo', {});
    const l2CategoriesRoute = routes.get('front::categories.type');

    let touchPointButton = 'rdr_header_search';

    if (pathToRegexp(l2CategoriesRoute).test(url)) {
      touchPointButton = 'rdr_l2_kategorie_search';
    } else if (pathToRegexp(categoriesRoute).test(url)) {
      touchPointButton = 'rdr_kategorie_search';
    }

    localStorage.setItem('wizardTouchPointButton', touchPointButton);
  };

  const fetchSuggestions = (searchQuery: string) => {
    if (searchQuery.length === 0 || searchQuery.length >= MIN_CHARS_TO_SEARCH) {
      getSearchCategories({ variables: { searchQuery } });
    }
  };

  const onSubmit = (e: MouseEvent<HTMLFormElement>) => {
    e.preventDefault();

    // If there is only one suggestino -- pick it
    // else find direct match by name for suggestion
    const directMatch =
      suggestions.length === 1
        ? suggestions[0]
        : suggestions.find((s) => normalizeString(s.name) === normalizeString(suggestValue));

    if (directMatch) {
      handleServiceSelect(directMatch);
    } else if (suggestValue.length) {
      analytics.user.searchStep1(suggestValue, suggestionsIds, suggestionsNames);
      setIsServiceListOpen(() => true);
    }
  };

  return (
    <div className="serviceSearch">
      <form className="serviceSearch__form" onSubmit={onSubmit}>
        <div className="serviceSearch__inputWrap">
          <ServiceSuggest
            suggestions={suggestions}
            value={suggestValue}
            onSelect={handleServiceSelect}
            onChange={setSuggestValue}
            onFocus={handleFocus}
            onBlur={onBlur}
            onSuggestionsFetch={fetchSuggestions}
            inHeader={inHeader}
          />
        </div>
        <button
          type="submit"
          className={c('serviceSearch__submit', {
            'serviceSearch__submit_inHeader': inHeader,
          })}
          aria-label={t`Szukaj...`}
          data-testid="serviceSuggestSubmit"
        />
      </form>
      {isServiceListOpen && (
        <ServiceList
          services={suggestions}
          isOpen={isServiceListOpen}
          onClose={onServiceListClose}
          onServiceSelect={handleServiceSelect}
        />
      )}
    </div>
  );
};

export default ServiceSearch;
