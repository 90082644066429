import { t } from '@lingui/macro';
import classnames from 'classnames';
import React, { Component } from 'react';

import { isMobileWebView } from 'common/helpers/helpers';
import logo from 'common/icons/fixly_logo.svg';
import analytics from 'utils/analytics/analytics';

import AdminMessage from '../AdminMessage';
import { AdminMessageOverlay$Props, AdminMessageOverlay$State } from './AdminMessageOverlay.helpers';
import './AdminMessageOverlay.scss';

const NO_SCROLL_CLASS_NAME = 'noscroll';

class AdminMessageOverlay extends Component<AdminMessageOverlay$Props, AdminMessageOverlay$State> {
  state = {
    isHiding: false,
    isShown: false,
  };

  componentDidUpdate(prevProps: AdminMessageOverlay$Props) {
    const messageLoaded = !prevProps.isLoaded && this.props.isLoaded;

    if (!this.state.isShown && (messageLoaded || this.props.forceShown)) {
      this.showMessage();
      if (!this.props.standalonePageMode) this.handleBodyScroll(true);
    }
  }

  handleBodyScroll = (disable: boolean) => {
    if (disable) {
      window.document.body.classList.add(NO_SCROLL_CLASS_NAME);
    } else {
      window.document.body.classList.remove(NO_SCROLL_CLASS_NAME);
    }
  };

  fireAnalytics = () => {
    if (isMobileWebView()) return;
    const { requestedMessageId, message } = this.props;
    const messageId = message ? message.id : requestedMessageId;
    const eventName = requestedMessageId
      ? 'admin_message_popup_click' // if requested id is present -> opened by click
      : 'admin_message_popup_show'; // if no requested id -> opened by default admin message

    analytics.provider.adminMessage(eventName, messageId, {
      rel_entity_type: 'admin_id',
      rel_entity_id: messageId,
    });
  };

  showMessage = () => this.setState(({ isShown }) => (isShown ? null : { isShown: true }), this.fireAnalytics);

  hidingMessage = () =>
    this.setState(
      () => ({ isHiding: true }),
      () => setTimeout(this.hideMessage, 500)
    );

  hideMessage = () => {
    const { onClose } = this.props;

    if (typeof onClose === 'function') {
      onClose();
    }

    this.setState(() => ({
      isHiding: false,
      isShown: false,
    }));
    this.handleBodyScroll(false);
  };

  render() {
    const { isShown, isHiding } = this.state;
    const { isLoading, message, standalonePageMode } = this.props;

    const overlayCN = classnames({
      'adminMessageOverlay': true,
      'adminMessageOverlay_open': isShown,
      'adminMessageOverlay_closing': isHiding,
      'adminMessageOverlay_ssr': standalonePageMode,
    });

    return (
      <div className={overlayCN}>
        <button
          type="button"
          className="adminMessageOverlay__close"
          onClick={this.hidingMessage}
          aria-label={t`Zamknij`}
        />
        <div className="adminMessageOverlay__wrapper">
          {!standalonePageMode && (
            <div className="adminMessageOverlay__header">
              <div className="adminMessageOverlay__logoContainer">
                <img className="adminMessageOverlay__logo" src={logo} alt="Fixly.pl logo" />
              </div>
            </div>
          )}
          <div className="adminMessageOverlay__content">
            <AdminMessage {...message} isLoading={isLoading} />
          </div>
        </div>
      </div>
    );
  }
}

export default AdminMessageOverlay;
