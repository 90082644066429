import React from 'react';
// TYPES

export type Image$Props = React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> & {
  src: string;
  backgroundImageMode?: boolean;
} & IConvertUrl$ImageProps;

export type IConvertUrl$PixelRatio = '1x' | '2x';

export type IConvertUrl$Size = 'xs' | 's' | 'm' | 'l' | 'xl';

export type IConvertUrl$ImageProps = {
  webp?: boolean;
  multiPixelRatios?: boolean;
  defaultJPEG?: boolean;
  size?: IConvertUrl$Size;
  lazy?: boolean;
};

// Implement support of multiple media support
export type IConvertUrl$MediaConfig = IConvertUrl$ImageProps & {
  rule: string;
  url: string;
};

// TODO: add support for multiple media requests
export type IConvertUrl$Config = IConvertUrl$ImageProps;

export type IConvertUrl$Result = {
  url: string;
  withSize?: string;
  withPixelRatios?: string[];
  webp?: IConvertUrl$Result;
};

// HELPERS

const fileExtensionRegex = /\.(\w+)$/;
const webPExtensionRegex = /\.webp$/;
const facebookAvatarRegex = /graph\.facebook\.com/;
const defaultAvatarRegex = /img\/provider\/avatar\.png$/i;
const supportedPixelRatios: IConvertUrl$PixelRatio[] = ['2x', '1x'];

function isFacebookAvatar(url: string): boolean {
  return facebookAvatarRegex.test(url);
}

function isDefaultAvatarUrl(url: string): boolean {
  return defaultAvatarRegex.test(url);
}

export function insertModifier(url: string, modifier: string): string {
  return url.replace(fileExtensionRegex, (match) => `:${modifier}${match}`);
}

function convertToPixelRatio(url: string, ratio: IConvertUrl$PixelRatio, size?: IConvertUrl$Size): string {
  let modifiedUrl = url;

  // Currently we do need even 1x ratio // We need no ratio for 1x images
  const urlRatioSuffix = ratio; // === '1x' ? '' : ratio;

  if (urlRatioSuffix) {
    modifiedUrl = insertModifier(modifiedUrl, ratio);
  }

  if (size) {
    modifiedUrl = insertModifier(modifiedUrl, size);
  }

  return `${modifiedUrl} ${ratio}`;
}

export function isWebP(url: string): boolean {
  return webPExtensionRegex.test(url);
}

export function getFileExt(url: string): string | undefined {
  // eslint-disable-next-line no-unused-vars
  const [, ext] = fileExtensionRegex.exec(url) || [];
  return ext;
}

export function replaceExtension(url: string, ext: string): string {
  if (!url || !ext || getFileExt(url) === ext) {
    return url;
  }

  const modifiedExtension = url.replace(fileExtensionRegex, `.${ext}`);

  return modifiedExtension === url ? `${url}.${ext}` : modifiedExtension;
}

export function extractConverterConfig(props: Image$Props) {
  const { webp, multiPixelRatios, size, defaultJPEG, ...ownProps } = props;

  const convertUrlConfig = {
    webp: typeof webp === 'boolean' ? webp : true,
    multiPixelRatios: typeof multiPixelRatios === 'boolean' ? multiPixelRatios : true,
    size,
    defaultJPEG: Boolean(defaultJPEG),
  };

  return {
    convertUrlConfig,
    ownProps,
  };
}

export function convertUrl(fileUrl: string, config: IConvertUrl$Config): IConvertUrl$Result {
  if (!fileUrl || isFacebookAvatar(fileUrl) || isDefaultAvatarUrl(fileUrl)) {
    return {
      url: fileUrl,
    };
  }

  const url = config.defaultJPEG && getFileExt(fileUrl) !== 'webp' ? replaceExtension(fileUrl, 'jpg') : fileUrl;

  let result: IConvertUrl$Result = { url };

  if (config.webp && !isWebP(url)) {
    result = {
      ...result,
      webp: convertUrl(replaceExtension(url, 'webp'), config),
    };
  }

  if (config.multiPixelRatios) {
    result = {
      ...result,
      withPixelRatios: supportedPixelRatios.map((ratio) => convertToPixelRatio(url, ratio, config.size)),
    };
  }

  if (config.size) {
    result = {
      ...result,
      withSize: insertModifier(url, config.size),
    };
  }

  return result;
}

export function getImageMimeType(url: string): string {
  const ext = getFileExt(url);

  return ext ? `image/${ext}` : 'image/jpg';
}
