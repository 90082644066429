import { t } from '@lingui/macro';
import classnames from 'classnames';
import React, { Component } from 'react';
import Autosuggest from 'react-autosuggest';

import isMobile from 'common/helpers/platformDetector';

import { Service } from '../ServiceSearch/ServiceSearch.helpers';
import './ServiceSuggest.scss';

type TSuggestionOptions = {
  isHighlighted: boolean;
  query: string;
};

type ServiceSuggestProps = {
  value: string;
  suggestions: Service[];
  inHeader?: boolean;
  onChange: (value: string) => void;
  onSelect: (service: Service) => void;
  onSuggestionsFetch: (value: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
};

class ServiceSuggest extends Component<ServiceSuggestProps> {
  onChange = (event: React.SyntheticEvent<HTMLInputElement>, { newValue }: { newValue: string }) => {
    this.props.onChange(newValue);
  };

  onSuggestionsFetchRequested = ({ value }: { value: string }) => {
    this.props.onSuggestionsFetch(value);
  };

  onSuggestionsClearRequested = () => false;

  onSuggestionSelected = (event: React.SyntheticEvent<HTMLInputElement>, { suggestion }: { suggestion: Service }) => {
    event.preventDefault();
    this.props.onSelect(suggestion);
  };

  getSuggestionValue = (suggestion: Service) => suggestion.name;

  getSuggestionClassName = (isHighlighted: boolean): string =>
    classnames({
      'serviceSuggest__suggestionContent': true,
      'serviceSuggest__suggestionContent_highlighted': isHighlighted,
    });

  shouldRenderSuggestions = () => this.props.suggestions.length > 0;

  renderSuggestion = (suggestion: Service, { isHighlighted }: TSuggestionOptions) => (
    <div
      className={this.getSuggestionClassName(isHighlighted)}
      dangerouslySetInnerHTML={{ __html: suggestion.htmlName || '' }}
    />
  );

  render() {
    const { value, suggestions, onFocus, onBlur, inHeader } = this.props;

    const placeholder = isMobile.isAnyMobile() ? t`Wyszukaj usługę...` : t`Wyszukaj usługę, której potrzebujesz...`;

    const inputProps = {
      placeholder,
      onChange: this.onChange,
      onFocus,
      onBlur,
      value,
      className: classnames({
        'serviceSuggest__input': true,
        'serviceSuggest__input_inHeader': inHeader,
      }),
      'aria-label': placeholder,
      'data-testid': 'serviceSuggestInput',
    };

    return (
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        onSuggestionSelected={this.onSuggestionSelected}
        getSuggestionValue={this.getSuggestionValue}
        renderSuggestion={this.renderSuggestion}
        shouldRenderSuggestions={this.shouldRenderSuggestions}
        /* @ts-expect-error type mismatch */
        inputProps={inputProps}
      />
    );
  }
}

export default ServiceSuggest;
