import { Plural } from '@lingui/macro';
import IntersectionObserver from '@researchgate/react-intersection-observer';
import React, { FC, MouseEventHandler, useState, memo } from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';

import Image from 'common/Image/Image';
import { prettifyNumber } from 'common/helpers/helpers';
import 'common/styles/SlickSlider.scss';
import routes from 'utils/routeTranslator';

import './CategoriesSlider.scss';

export type CategorySlide = {
  id: string;
  slug: string;
  name: string;
  imageUrl: string;
  requestsCount?: number;
  shouldUseNativeNavigation?: boolean;
};

type SlideProps = {
  category: CategorySlide;
  city?: string;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
};

const Slide: FC<SlideProps> = (props) => {
  const { category } = props;
  const href = routes.get('front::category', {
    slug: category.slug,
    city: props.city,
  });

  return (
    <div className="categorySlide">
      <Link className="categorySlide__link" to={href} onClick={props.onClick}>
        <>
          {category.imageUrl && (
            <Image
              src={category.imageUrl}
              webp
              multiPixelRatios
              size="l"
              className="categorySlide__coverImg"
              aria-hidden
            />
          )}
          <span className="categorySlide__label">{category.name}</span>
          {Boolean(category.requestsCount) && (
            <span className="categorySlide__requestsCount">
              <b>{prettifyNumber(category.requestsCount || 0)}</b>{' '}
              <Plural value={category.requestsCount} one="zapytanie" few="zapytania" other="zapytań" />
            </span>
          )}
        </>
      </Link>
    </div>
  );
};

type Props = {
  categories: CategorySlide[];
  city?: string;
  isHomepage?: boolean;
  isDashboard?: boolean;
  shouldUseNativeNavigation?: boolean;
};

const CategoriesSlider: FC<Props> = (props) => {
  const sliderSettings = {
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: props.isHomepage ? 20 : 5,
    draggable: false,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 568,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const [shouldLoadImages, setShouldLoadImages] = useState<boolean>(false);

  const handleIntersectionChange = ({ isIntersecting }: IntersectionObserverEntry, unobserve: Function): void => {
    if (isIntersecting) {
      setShouldLoadImages(true);
      unobserve();
    }
  };

  const handleLinkClick: MouseEventHandler<HTMLAnchorElement> = (e) => {
    if (props.isHomepage) {
      localStorage.setItem('wizardTouchPointButton', 'rdr_home_link');
    }

    if (props.isDashboard) {
      localStorage.setItem('wizardTouchPointButton', 'mojefixly');
    }

    if (!props.shouldUseNativeNavigation) {
      return;
    }
    e.preventDefault();
    e.stopPropagation();

    window.location.href = e.currentTarget.href;
  };

  return (
    <IntersectionObserver onChange={handleIntersectionChange} rootMargin="0% 0% 10%">
      <div className="categoriesSlider">
        <div className="categoriesSlider__list">
          <Slider {...sliderSettings}>
            {props.categories.map((category) => (
              <Slide
                key={category.slug}
                category={{
                  ...category,
                  imageUrl: shouldLoadImages ? category.imageUrl : '',
                }}
                city={props.city}
                onClick={handleLinkClick}
              />
            ))}
          </Slider>
        </div>
      </div>
    </IntersectionObserver>
  );
};

export default memo(CategoriesSlider);
