import React from 'react';

export const AppStoreIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20">
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M14.693 10.54c-.02-2.147 1.78-3.192 1.863-3.241-1.02-1.468-2.6-1.669-3.155-1.684-1.326-.138-2.613.784-3.29.784-.689 0-1.73-.77-2.85-.747-1.444.021-2.795.847-3.535 2.13-1.529 2.613-.388 6.454 1.075 8.567.733 1.035 1.589 2.191 2.71 2.15 1.095-.045 1.504-.691 2.827-.691 1.311 0 1.695.69 2.838.665 1.176-.019 1.917-1.04 2.624-2.084.847-1.186 1.187-2.355 1.2-2.414-.028-.01-2.285-.86-2.307-3.434zM12.534 4.225c.59-.728.993-1.719.881-2.725-.853.037-1.92.583-2.535 1.296-.543.628-1.028 1.656-.904 2.624.959.07 1.944-.478 2.558-1.195z"
      clipRule="evenodd"
    />
  </svg>
);

/* eslint max-len:0 */
