import { Trans } from '@lingui/macro';
import c from 'classnames';
import React, { FC, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';

import { withNoSSR } from 'Components/NoSSR';
import platformDetector from 'common/helpers/platformDetector';
import config from 'config/config';
import { CloseButton, Button } from 'uikit/Button';
import { TextP3 } from 'uikit/Text';
import analytics from 'utils/analytics/analytics';
import localStorage from 'utils/localStorage/localStorage';

import './AndroidBanner.scss';

const APP_INSTALL_URL = 'https://app.fixly.pl/UZRBtJsmzpb';

const AndroidBanner: FC = () => {
  const [isShown, setIsShown] = useState<boolean>(false);

  const CN = c('androidBanner', {
    'androidBanner_shown': isShown,
  });

  const onClose = (eventName: string) => {
    localStorage.setItem('fixly_android_banner_closed', 1);
    analytics.trackEvent(eventName, { touch_point_button: 'banner' });
    setIsShown(false);
  };

  useEffect(() => {
    const listener: EventListener = (e) => {
      e.preventDefault();
      analytics.trackEvent('android_native_banner_show');
    };

    window.addEventListener('beforeinstallprompt', listener);

    if (
      document.referrer.includes(window.location.hostname) &&
      !localStorage.getItem('fixly_android_banner_closed') &&
      !platformDetector.iOS()
    ) {
      setIsShown(true);
    }

    return () => {
      window.removeEventListener('beforeinstallprompt', listener);
    };
  }, []);

  return (
    <div className={CN}>
      <Helmet>
        <meta name="apple-itunes-app" content={`app-id=${config.IOS_APP_ID_CUSTOMER}`} />
      </Helmet>

      <CloseButton className="androidBanner__close" onClick={() => onClose('user_app_button_dismiss_android')} />
      <div className="androidBanner__content">
        <TextP3 bold>
          <Trans>Potrzebujesz wykonawcy?</Trans>
        </TextP3>
        <TextP3>
          <Trans>Pobierz aplikację mobilną Fixly!</Trans>
        </TextP3>
      </div>
      <Button
        as="a"
        href={APP_INSTALL_URL}
        target="_blank"
        rel="noreferrer"
        onClick={() => onClose('user_app_button_click_android')}
      >
        <Trans>Pobierz</Trans>
      </Button>
    </div>
  );
};

export default withNoSSR(AndroidBanner);
