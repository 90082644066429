import classnames from 'classnames';
import React, { FC } from 'react';

import ArticleCard from './ArticleCard';
import './ArticleList.scss';

export type ArticlePreviewItem = {
  title: string;
  description?: string;
  link: string;
  createdAt: string;
  img: string;
};

type Props = {
  className?: string;
  items: ArticlePreviewItem[];
};

const ArticleList: FC<Props> = (props) => {
  const hostCN = classnames('articleList', props.className);

  return (
    <div className={hostCN}>
      {props.items.map((item, index) => (
        <ArticleCard key={`article-${index}`} data={item} />
      ))}
    </div>
  );
};

export default ArticleList;
