import React, { PureComponent } from 'react';

type AdminMessageImage$Props = {
  imgUrl: string;
  linkUrl: string | null | undefined;
};

class AdminMessageImage extends PureComponent<AdminMessageImage$Props> {
  renderImage() {
    const { imgUrl } = this.props;

    return <img src={imgUrl} className="adminMessage__image" />;
  }
  render() {
    const { linkUrl } = this.props;

    return linkUrl ? (
      <a href={linkUrl} className="adminMessage__media" target="_blank" rel="noopener noreferrer">
        {this.renderImage()}
      </a>
    ) : (
      <figure className="adminMessage__media">{this.renderImage()}</figure>
    );
  }
}

export default AdminMessageImage;
