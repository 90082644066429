import { QueryHookOptions, useQuery, gql } from '@apollo/client';

export type TAdminMessage = {
  id: string;
  title: string;
  content: string;
  authorName: string;
  authorRole: string;
  authorAvatarUrl: string;
  topPictureImageUrl?: string;
  topPictureLinkUrl?: string;
  bottomPictureImageUrl?: string;
  bottomPictureLinkUrl?: string;
};

export const adminMessageQuery = gql`
  query adminMessage($id: ID) {
    adminMessage(id: $id) {
      id
      title
      content
      authorName
      authorRole
      authorAvatarUrl
      topPictureImageUrl
      topPictureLinkUrl
      bottomPictureImageUrl
      bottomPictureLinkUrl
    }
  }
`;

export const useAdminMessageQuery = (options?: QueryHookOptions<{ adminMessage: TAdminMessage }>) => {
  return useQuery(adminMessageQuery, options);
};
