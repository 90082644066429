import React, { useEffect, useState } from 'react';

import ProtectedContent from 'Components/ProtectedContent/ProtectedContent';
import { useAdminMessageQuery } from 'utils/graphql/providerAdminMessage';

import AdminMessageOverlay from '../AdminMessageOverlay/AdminMessageOverlay';

type AdminMessageData$Props = {
  isMessageShown?: boolean;
  id?: string | null;
  standalonePageMode?: boolean;
  onMessageClose?: () => void;
};

const AdminMessageData: React.FC<AdminMessageData$Props> = ({
  id,
  standalonePageMode,
  isMessageShown: isShowForced,
  onMessageClose,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const { data: adminMessageData, loading } = useAdminMessageQuery({
    variables: {
      id,
    },
    ssr: false,
  });

  useEffect(() => {
    if (adminMessageData?.adminMessage && !id) {
      setIsOpen(true);
    }
  }, [id, adminMessageData]);

  const handleClose = () => {
    onMessageClose?.();
    setIsOpen(false);
  };

  if (!adminMessageData) return null;

  const { adminMessage } = adminMessageData;

  return (
    <ProtectedContent loginRequired>
      <AdminMessageOverlay
        isLoading={loading}
        isLoaded={Boolean(adminMessage)}
        forceShown={isOpen || Boolean(isShowForced)}
        onClose={handleClose}
        message={adminMessage}
        standalonePageMode={standalonePageMode}
        requestedMessageId={id}
      />
    </ProtectedContent>
  );
};

export default AdminMessageData;
