import c from 'classnames';
import React, { FC } from 'react';
import Slider from 'react-slick';

import PublicBanner from 'Components/PublicBanner/PublicBanner';
import analytics from 'utils/analytics/analytics';

import { useHomepageBannersQuery } from './HomepageBanners.helpers';
import './HomepageBanners.scss';

interface Props {
  className?: string;
}

const autoplaySpeed = 5 * 1000; // 5s

const HomepageBanners: FC<Props> = (props) => {
  const { data } = useHomepageBannersQuery();
  const banners = data?.homepageBanners || [];

  return (
    <div className={c('HomepageBanners', props.className)}>
      <Slider
        className="HomepageBanners__slider"
        dots
        draggable={false}
        arrows={false}
        autoplay
        autoplaySpeed={autoplaySpeed}
      >
        {banners.map((banner) => (
          <div className="HomepageBanners__itemWrapper" key={banner.id} data-testid={banner.id}>
            <PublicBanner
              title={banner.titleText}
              imageUrl={banner.imageUrl}
              href={banner.actionUrl}
              openInNewTab={!banner.titleText}
              onClick={() => {
                analytics.trackEvent('user_banner_click', {
                  rel_entity_id: banner.id,
                });
              }}
            />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default HomepageBanners;
