import React, { Component } from 'react';
import classnames from 'classnames';

import AdminMessageImage from './AdminMessageImage';

import { AdminMessage$Props, AdminMessage$State } from './AdminMessage.helpers';

import './AdminMessage.scss';

class AdminMessage extends Component<AdminMessage$Props, AdminMessage$State> {
  renderSpinner() {
    return (
      <div className="adminMessage__spinnerWrap">
        <div className="adminMessage__spinner" />
      </div>
    );
  }

  renderAuthor() {
    const { authorName, authorAvatarUrl, authorRole } = this.props;

    return (
      <div className="adminMessage__author">
        <img
          src={authorAvatarUrl}
          alt={authorName || 'avatar'}
          className="adminMessage__authorAvatar"
          width="40"
          height="40"
        />
        <p className="adminMessage__authorInfo">
          <span className="adminMessage__authorName">{authorName}</span>
          <span className="adminMessage__authorRole">{authorRole}</span>
        </p>
      </div>
    );
  }

  renderMedia(imgUrl: string, linkUrl?: string) {
    return <AdminMessageImage imgUrl={imgUrl} linkUrl={linkUrl} />;
  }

  render() {
    const {
      title,
      content,
      topPictureImageUrl,
      topPictureLinkUrl,
      bottomPictureImageUrl,
      bottomPictureLinkUrl,
      isLoading,
    } = this.props;

    const messageCN = classnames({
      'adminMessage': true,
      'adminMessage_loading': isLoading,
    });

    return (
      <div className="adminMessageWrap">
        {isLoading && this.renderSpinner()}
        <div className={messageCN}>
          <h1 className="adminMessage__title">{title}</h1>
          {topPictureImageUrl && this.renderMedia(topPictureImageUrl, topPictureLinkUrl)}
          <div className="adminMessage__text" dangerouslySetInnerHTML={{ __html: content }} />
          {this.renderAuthor()}
          {bottomPictureImageUrl && this.renderMedia(bottomPictureImageUrl, bottomPictureLinkUrl)}
        </div>
      </div>
    );
  }
}

export default AdminMessage;
