import React from 'react';
// import { Redirect } from 'react-router-dom';

import routes from 'utils/routeTranslator';
import getLoginToken from 'common/helpers/getLoginToken';
import { CurrentUserConsumer, TCurrentUserState } from 'Components/CurrentUser/CurrentUser';

type TProps = {
  loginRequired?: boolean;
  guestRedirect?: string;
  providerRedirect?: string;
  customerRedirect?: string;
};

// TODO: replace hard redirects with react router after migration to single entry point

const ProtectedContent: React.FC<TProps> = ({
  loginRequired,
  guestRedirect,
  providerRedirect,
  customerRedirect,
  children,
}) => {
  const guestProtected = Boolean(loginRequired || guestRedirect);
  return (
    <CurrentUserConsumer>
      {(context: TCurrentUserState | undefined) => {
        if (typeof window === 'undefined') {
          if (guestProtected) return null;
          return children;
        }

        const { currentUser, loaded } = context || {};
        const { location } = window;

        if (!loaded && guestProtected) return null;
        if (getLoginToken() || window.isFetchingLoginToken) return null;

        if (!currentUser && loginRequired) {
          location.href = `${routes.get('front::auth.user.login')}?redirect_url=${window.location.pathname}`;
          return null;
          // return <Redirect to={guestRedirect} />;
        }
        if (!currentUser && guestRedirect) {
          location.href = guestRedirect;
          return null;
          // return <Redirect to={guestRedirect} />;
        }
        if (currentUser && !currentUser.isProvider && customerRedirect) {
          location.href = customerRedirect;
          return null;
          // return <Redirect to={customerRedirect} />;
        }
        if (currentUser && currentUser.isProvider && providerRedirect) {
          location.href = providerRedirect;
          return null;
          // return <Redirect to={providerRedirect} />;
        }
        return children;
      }}
    </CurrentUserConsumer>
  );
};

export default ProtectedContent;
