import React, { memo, useState } from 'react';
import IntersectionObserver from '@researchgate/react-intersection-observer';

import { Image$Props, convertUrl, getImageMimeType, extractConverterConfig } from './Image.helpers';

export const Image: React.FC<Image$Props> = (props) => {
  const [shouldLoad, setShouldLoad] = useState(!props.lazy);
  const { convertUrlConfig, ownProps } = extractConverterConfig(props);

  const { src, backgroundImageMode, lazy, alt, ...elementProps } = ownProps;

  const { url, webp, withPixelRatios, withSize } = convertUrl(src, convertUrlConfig);

  const handleIntersectionChange = ({ isIntersecting }: IntersectionObserverEntry, unobserve: Function): void => {
    if (isIntersecting) {
      setShouldLoad(true);
    }
  };

  // Add support for background image
  // if (backgroundImageMode) {
  //   return (
  //     <div style={{ backgroundImage: `url(${src})`, backgroundSize: 'cover' }} {...elementProps} />
  //   );
  // }

  return (
    <IntersectionObserver onChange={handleIntersectionChange}>
      <picture>
        {webp && (
          <source
            type="image/webp"
            srcSet={webp.withPixelRatios ? webp.withPixelRatios.join(', ') : webp.withSize || webp.url}
          />
        )}
        {withPixelRatios && <source type={getImageMimeType(url)} srcSet={withPixelRatios.join(',')} />}
        {shouldLoad && <img src={withSize || url} {...elementProps} alt={alt || ''} />}
      </picture>
    </IntersectionObserver>
  );
};

export default memo(Image);
