import { Trans } from '@lingui/macro';
import React, { FC, memo } from 'react';

import ActivityFeed from 'Components/ActivityFeed/ActivityFeed.async';
import ServiceSearch from 'Components/ServiceSearch/ServiceSearch';
import TopCategories from 'Components/TopCategories/TopCategories.async';
import { StarsIcon } from 'common/icons/StarsIcon';

import {
  THomepageData$ProvidersCount,
  prettifyProviderCounts,
  THomepageData$FeaturedProvider,
} from '../Homepage.helpers';
import './HomepageHeader.scss';

type Props = {
  counts: THomepageData$ProvidersCount;
  featuredProviders: THomepageData$FeaturedProvider[];
};

const HomepageHeader: FC<Props> = (props) => {
  const { registered, online } = prettifyProviderCounts(props.counts);

  return (
    <header className="homepageHeader">
      <section className="homepageHeader__mainInfoContainer">
        <div className="homepageHeader__titleCountersContainer">
          <h1 className="homepageHeader__mainTitle">
            <Trans>
              Sprawdzeni wykonawcy
              <br />
              Fixly - do usług!
            </Trans>
          </h1>
          <div className="homepageHeader__registeredUsersCount">
            <div>
              <span>
                <Trans>
                  Mamy <b>{registered}</b> wykonawców, ocenianych średnio na
                </Trans>
              </span>
              &nbsp;
              <StarsIcon className="homepageHeader__registeredUsersStar" /> 4.7,&nbsp;
            </div>
            <div>
              <Trans>
                <b>{online}</b> z nich jest teraz online
              </Trans>
            </div>
          </div>
          <div id="mysticPlace">
            <div className="homepageHeader__serviceSearchWrapper" id="fixlyHomePageSearch">
              <ServiceSearch />
            </div>
          </div>
        </div>
      </section>
      <section className="homepageHeader__activityFeedContainer homepageHeader__activityFeedContainer_withPromoVideo">
        {/* NOTE: pay attention that .homepage class is used below */}
        <h2 className="homepage__subtitle homepage__subtitle_activityFeed">
          <Trans>Aktualności</Trans>
        </h2>

        <div className="homepageHeader__activityFeed">
          {/* @ts-expect-error type mismatch */}
          <ActivityFeed withDynamicHeight itemsContainerClassName="homepageHeader__feedItems" />
        </div>
      </section>
      <section className="homepageHeader__mainNavigation">
        <div className="homepageHeader__navigationContainer">
          <TopCategories homepage shouldUseNativeNavigation />
        </div>
      </section>
    </header>
  );
};

export default memo(HomepageHeader);
