import loadable from '@loadable/component';

import AsyncLoader from 'Components/AsyncLoader/AsyncLoader';

/* @ts-expect-error type mismatch */
export default loadable(() => import('./ActivityFeed'), {
  fallback: AsyncLoader(),
});

/* eslint no-console:0 */
