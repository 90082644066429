import { Trans } from '@lingui/macro';
import React, { FC, memo } from 'react';

import './ServiceValues.scss';

const ServicesValues: FC = () => {
  return (
    <section className="serviceValues">
      <div className="serviceValues__item">
        <h3 className="serviceValues__title">
          <Trans>Nie musisz szukać</Trans>
        </h3>
        <p className="serviceValues__description">
          <Trans id="homepage.values.description_1">
            Wystarczy, że odpowiesz na kilka krótkich pytań, a odezwą się do Ciebie zainteresowani specjaliści z Twojej
            okolicy.
          </Trans>
        </p>
      </div>
      <div className="serviceValues__item">
        <h3 className="serviceValues__title">
          <Trans>Polecamy polecanych</Trans>
        </h3>
        <p className="serviceValues__description">
          <Trans id="homepage.values.description_2">
            Szukasz kogoś z polecenia? Nasi użytkownicy codziennie dodają opinie o wykonawcach ponad 200 usług.
          </Trans>
        </p>
      </div>
      <div className="serviceValues__item">
        <h3 className="serviceValues__title">
          <Trans>Cenimy Twój czas</Trans>
        </h3>
        <p className="serviceValues__description">
          <Trans id="homepage.values.description_3">
            Wykonawcy skontaktują się z Tobą tego samego dnia! O ich wiadomościach poinformujemy Cię mailem lub SMSem.
          </Trans>
        </p>
      </div>
    </section>
  );
};

export default memo(ServicesValues);
