import React, { SVGAttributes } from 'react';

export const StarsIcon = (props: SVGAttributes<SVGElement>) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M19.6501 9.04L14.8101 8.62L12.9201 4.17C12.5801 3.36 11.4201 3.36 11.0801 4.17L9.19013 8.63L4.36013 9.04C3.48013 9.11 3.12013 10.21 3.79013 10.79L7.46013 13.97L6.36013 18.69C6.16013 19.55 7.09013 20.23 7.85013 19.77L12.0001 17.27L16.1501 19.78C16.9101 20.24 17.8401 19.56 17.6401 18.7L16.5401 13.97L20.2101 10.79C20.8801 10.21 20.5301 9.11 19.6501 9.04Z"
      fill="#FFD700"
    />
  </svg>
);

/* eslint max-len:0 */
