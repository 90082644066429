import React, { Component } from 'react';

import AdminMessageData from './AdminMessageData/AdminMessageData';
import { AdminMessageController$State, TAdminMessageOpener } from './AdminMessage.helpers';
import { withNoSSR } from 'Components/NoSSR';

class AdminMessageController extends Component<any, AdminMessageController$State> {
  state = {
    id: null,
    isMessageShown: false,
  };

  componentDidMount = () => {
    window.addEventListener('message', this.parseMessageOpener);
  };

  componentWillUnmount = () => {
    window.removeEventListener('message', this.parseMessageOpener);
  };

  parseMessageOpener = (opener: TAdminMessageOpener): void => {
    if (
      opener.origin === window.location.origin &&
      opener.data &&
      opener.data.id &&
      opener.data.type === 'open_admin_message'
    ) {
      this.showMessage(opener.data.id);
    }
  };

  showMessage = (id: string) =>
    this.setState(() => ({
      id,
      isMessageShown: true,
    }));

  onMessageClose = () => this.setState(() => ({ isMessageShown: false }));

  render() {
    const { id, isMessageShown } = this.state;

    return (
      <div className="adminMessageController">
        <AdminMessageData id={id} isMessageShown={isMessageShown} onMessageClose={this.onMessageClose} />
      </div>
    );
  }
}

export default withNoSSR(AdminMessageController);
