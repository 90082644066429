import { useQuery, gql } from '@apollo/client';

export interface HomepageBanner {
  id: string;
  titleText?: string;
  imageUrl?: string;
  actionUrl: string;
}

interface HomepageBannersResponse {
  homepageBanners: HomepageBanner[];
}

const homepageBannersQuery = gql`
  query HomepageBanners {
    homepageBanners {
      id
      titleText
      imageUrl(scale: x2)
      actionUrl
    }
  }
`;

export const useHomepageBannersQuery = () => {
  return useQuery<HomepageBannersResponse>(homepageBannersQuery, {
    ssr: false,
  });
};
