import React from 'react';

export const GooglePlayIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20">
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M2.523 19c-.317-.086-.523-.404-.523-.896V1.874c0-.455.175-.762.451-.874l7.89 9.045L2.524 19zm1.086-.345l9.498-5.44-2.313-2.652-7.185 8.092zm9.563-11.852L3.807 1.437l7 8.075 2.365-2.709zm.636.364l3.669 2.103c.694.398.7 1.04 0 1.44l-3.762 2.156-2.454-2.83 2.547-2.869z"
      clipRule="evenodd"
    />
  </svg>
);

/* eslint max-len:0 */
