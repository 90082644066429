import { Trans } from '@lingui/macro';
import React, { FC } from 'react';

import { NoSSR } from 'Components/NoSSR';
import isMobile from 'common/helpers/platformDetector';
import { AppStoreIcon } from 'common/icons/AppStoreIcon';
import { GooglePlayIcon } from 'common/icons/GooglePlayIcon';
import { Button } from 'uikit/Button';

import './ClientAppBanner.scss';

const ClientAppBanner: FC = () => {
  return (
    <div className="clientAppBanner">
      <h3 className="clientAppBanner__caption">
        <Trans>Potrzebujesz pomocy wykonawcy?</Trans>{' '}
        <strong>
          <Trans>Pobierz aplikację mobilną Fixly!</Trans>
        </strong>
      </h3>
      <div className="clientAppBanner__buttonsWrapper">
        <NoSSR>
          {(!isMobile.iOS() || !isMobile.isAnyMobile()) && (
            <Button
              as="a"
              href="https://app.fixly.pl/homepage_gp"
              target="_blank"
              rel="noopener"
              className="clientAppBanner__button"
            >
              <GooglePlayIcon aria-hidden />
              <Trans>Pobierz z Google Play</Trans>
            </Button>
          )}
          {(isMobile.iOS() || !isMobile.isAnyMobile()) && (
            <Button
              as="a"
              href="https://app.fixly.pl/homepage_ios"
              target="_blank"
              rel="noopener"
              className="clientAppBanner__button"
            >
              <AppStoreIcon aria-hidden />
              <Trans>Pobierz z App Store</Trans>
            </Button>
          )}
        </NoSSR>
      </div>
      <div className="clientAppBanner__mobilePhones"></div>
    </div>
  );
};

export default ClientAppBanner;
