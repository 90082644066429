import { useLazyQuery, gql } from '@apollo/client';

export type Service = {
  id: string;
  slug: string;
  name: string;
  htmlName?: string;
};

export type ServiceSearch$Props = {
  inHeader?: boolean;
  onServiceSelect?: () => void;
  onFocus?: () => void;
  onBlur?: () => void;
};

export const SearchL4CategoriesQuery = gql`
  query SearchL4Categories($searchQuery: String!) {
    searchL4Categories(searchQuery: $searchQuery) {
      id
      name
      htmlName
      slug
    }
  }
`;

export const PopularCategoriesQuery = gql`
  query PopularCategories {
    userPopularServices {
      id
      slug
      name
    }
  }
`;

export const useSearchL4CategoriesLazyQuery = () =>
  useLazyQuery<{ searchL4Categories: Service[] }, { searchQuery: string }>(SearchL4CategoriesQuery, {});

export const usePopularCategoriesLazyQuery = () =>
  useLazyQuery<{ userPopularServices: Service[] }>(PopularCategoriesQuery);

export const normalizeString = (s: string): string => s.trim().toLowerCase();

export const formatSuggestions = (suggestions: Service[] = []) =>
  suggestions.map((s: Service) => ({ ...s, htmlName: s.name }));
