import classnames from 'classnames';
import React, { CSSProperties, FC, memo, ReactFragment } from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as ArrowButtonIcon } from 'common/icons/ArrowButtonIcon.svg';
import { TextP1 } from 'uikit/Text';

import './PublicBanner.scss';

type Props = {
  className?: string;
  title?: string;
  imageUrl?: string;
  href: string;
  openInNewTab?: boolean;
  onClick?: () => void;
};

const getRelativePath = (value: string): string => {
  const url = new URL(value);
  return url.pathname + url.search;
};

const PublicBanner: FC<Props> = (props) => {
  // If banner has no title then treat it as a full width image, hide arrow button

  const hostCN = classnames(
    'publicBanner',
    {
      'publicBanner_contentless': !props.title,
    },
    props.className
  );

  const relativeUrl = getRelativePath(props.href);

  const bannerStyle: CSSProperties | undefined = props.imageUrl
    ? { backgroundImage: `url('${props.imageUrl}')` }
    : undefined;

  const target = props.openInNewTab ? '_blank' : undefined;
  const rel = props.openInNewTab ? 'nofollow noopener noreferrer' : undefined;

  const innerContent: ReactFragment = (
    <>
      <TextP1 bold className="publicBanner__text">
        {props.title}
      </TextP1>

      {props.title && (
        <div className="publicBanner__arrow">
          <ArrowButtonIcon aria-hidden />
        </div>
      )}
    </>
  );

  if (props.openInNewTab) {
    return (
      <a className={hostCN} href={props.href} style={bannerStyle} onClick={props.onClick} target={target} rel={rel}>
        {innerContent}
      </a>
    );
  }

  return (
    <Link className={hostCN} to={relativeUrl} style={bannerStyle} onClick={props.onClick}>
      {innerContent}
    </Link>
  );
};

export default memo(PublicBanner);
