import parseQueryString from 'common/helpers/parseQueryString';

type queryLoginToken = {
  loginToken?: string;
  login_token?: string;
};

const getLoginToken: () => string | null = () => {
  if (typeof window === 'undefined') return null;

  const query: queryLoginToken = parseQueryString(window.location.search);
  const loginToken = query.loginToken || query.login_token;
  return loginToken || null;
};

export default getLoginToken;
